import { Routes, Route } from "react-router-dom";
import "./Auth.css";
import Meeting from "./components/Meeting";
import Auth from "./components/Auth";
import Rooms from "./components/Rooms";

function App() {
  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<Auth type="login" />} />
        <Route path="/meeting/:roomId" element={<Meeting />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/login" element={<Auth type="login" />} />
        <Route path="/signup" element={<Auth type="signup" />} />
      </Routes>
    </div>
  );
}

export default App;