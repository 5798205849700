import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import io from 'socket.io-client';
import '../Meeting-room.css'

const Room = () => {
  const [socket, setSocket] = useState(null);
  const { roomId } = useParams();
  const [userCount, setUserCount] = useState(0);
  const [userId, setUserId] = useState(0);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState("");
  const navigate = useNavigate();

  const localStreamRef = useRef(null);
  const videoGridRef = useRef(null);
  const peersRef = useRef({});

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storeToken = localStorage.getItem('token')  
    if (storedUser && storeToken ) {
      const user = JSON.parse(storedUser);
      console.log(user,'this is user');
      
      setUserId(user.id); 
      console.log(userId, 'userId');
      
    }
    else{
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    const newSocket = io("https://api.begelled.com");
    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected with ID:", newSocket.id);
      newSocket.emit("joinRoom", roomId);
    });

    newSocket.on("getTotalUser", (count) => {
      setUserCount(count);
    });

    newSocket.emit("getExistingUsers", roomId);

    newSocket.on("existingUsers", (existingUsers) => {
      existingUsers.forEach(async (existingUserId) => {
        const peerConnection = createPeerConnection(existingUserId, newSocket);
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);
        newSocket.emit("offer", { to: existingUserId, offer });
      });
    });


    newSocket.on("newUser", async (userId) => {
      console.log(`New user joined: ${userId}`);
      
      // Create a peer connection and send your stream
      const peerConnection = createPeerConnection(userId, newSocket);
      const offer = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offer);
    
      // Send the offer to the new user
      newSocket.emit("offer", { to: userId, offer });
    });



    newSocket.on("userDisconnected", (disconnectedUserId) => {
      if (peersRef.current[disconnectedUserId]) {
        peersRef.current[disconnectedUserId].close();
        delete peersRef.current[disconnectedUserId];
      }
      
      const videoTiles = Array.from(videoGridRef.current.querySelectorAll("video"));
      videoTiles.forEach((video) => {
        if (video.dataset.userId === disconnectedUserId) {
          videoGridRef.current.removeChild(video);
        }
      });
    });
    
    

    newSocket.on("offer", async ({ from, offer }) => {
      // Check if peer connection exists, if not, create one
      let peerConnection = peersRef.current[from];
      if (!peerConnection) {
        peerConnection = createPeerConnection(from, newSocket);
      }
    
      try {
        await peerConnection.setRemoteDescription(offer);
        const answer = await peerConnection.createAnswer();
        await peerConnection.setLocalDescription(answer);
    
        newSocket.emit("answer", { to: from, answer });
      } catch (error) {
        console.error("Error handling offer:", error);
      }
    });
    

    newSocket.on("answer", async ({ from, answer }) => {
      const peerConnection = peersRef.current[from];
    
      if (!peerConnection) {
        console.error(`PeerConnection not found for user ${from}`);
        return; // Avoid attempting to set remote description on a null object
      }
    
      try {
        await peerConnection.setRemoteDescription(answer);
      } catch (error) {
        console.error("Error handling answer:", error);
      }
    });
    

    newSocket.on("ice-candidate", ({ from, candidate }) => {
      const peerConnection = peersRef.current[from];
      if (peerConnection && candidate) {
        peerConnection.addIceCandidate(candidate);
      }
    });
    

    newSocket.on("receiveMessage", (data) => {
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [roomId]);


  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        localStreamRef.current = stream;
  
        const localVideo = document.createElement("video");
        localVideo.srcObject = stream;
        localVideo.autoplay = true;
        localVideo.muted = true;
        localVideo.className = "video-tile";
        videoGridRef.current.appendChild(localVideo);
      })
      .catch((error) => {
        console.error("Error accessing camera/mic:", error);
      });
  }, []);
  
  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        socket.emit("newUser", roomId);
      });
    }
  }, [socket, roomId]);
  
  
  useEffect(() => {
    if (!videoGridRef.current) {
      console.error("videoGridRef is not attached to an element!");
    }
  }, []);
  

const createPeerConnection = (userId, socket) => {
  if (peersRef.current[userId]) {
    console.warn(`PeerConnection already exists for user ${userId}`);
    return peersRef.current[userId];
  }

  const peerConnection = new RTCPeerConnection();
  
  if (localStreamRef.current) {
    localStreamRef.current.getTracks().forEach((track) => {
      peerConnection.addTrack(track, localStreamRef.current);
    });
  } else {
    console.error("Local stream is not initialized yet!");
  }

  peerConnection.ontrack = (event) => {
    if (videoGridRef.current) {
      const existingVideos = Array.from(videoGridRef.current.querySelectorAll("video"));
      if (!existingVideos.some((video) => video.srcObject === event.streams[0])) {
        const remoteVideo = document.createElement("video");
        remoteVideo.srcObject = event.streams[0];
        remoteVideo.autoplay = true;
        remoteVideo.className = "video-tile";
        remoteVideo.dataset.userId = userId; // For cleanup
        videoGridRef.current.appendChild(remoteVideo);
      }
    }
  };

  peerConnection.onicecandidate = (event) => {
    if (event.candidate) {
      socket.emit("ice-candidate", { to: userId, candidate: event.candidate });
    }
  };

  peersRef.current[userId] = peerConnection;

  return peerConnection;
};

 const handleMessageSubmit = (event) => {
    event.preventDefault();
    if (message.trim() && socket) {
      const messageData = { roomId, message, senderId: socket.id };
      
      socket.emit("sendMessage", messageData);
      setMessage("");
    }
  };

 return (
    <div className="app-container">
      <div className="meeting-container">
        <div className="video-grid" ref={videoGridRef}>
      {/* {Array.from({ length: userCount }).map((_, index) => (
        <div className="video-tile" key={index}>
          User {index + 1}
        </div>
      ))} */}
    </div>
        {isChatOpen && (
          <div className="sidebar">
            <div className="chat-section">
              <h2>Chat</h2>
             <div className="chat-messages">
        {Array.isArray(messages) &&
          messages.map((msg, index) => (
            <p key={index}>
              {msg.message}
            </p>
          ))}
      </div>

              <form onSubmit={handleMessageSubmit}>
              <input
                type="text"
                placeholder="Type a message..."
                className="chat-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleMessageSubmit(e);
                  }
                }}
              />
            </form>
            </div>
          </div>
        )}
      </div>
      <div className="controls">
        <button className="control-button" onClick={toggleChat}>
          {isChatOpen ? "Close Chat" : "Open Chat"}
        </button>
        <button className="control-button">Mute</button>
        <button className="control-button">Stop Video</button>
        <button className="control-button end-call">End Call</button>
      </div>
    </div>
  );

};

export default Room;