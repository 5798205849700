  // src/context/SocketProvider.js
  import React, { createContext, useContext, useEffect, useMemo } from 'react';
  import { io } from "socket.io-client";
  const socket = io("https://api.begelled.com");
  
  const SocketContext = createContext();

  export const useSocket = () => useContext(SocketContext);

  const SocketProvider = ({ children }) => {
    const socket = useMemo(() => io('https://api.begelled.com'), []);

    useEffect(() => {
      socket.on('connection', () => {
        console.log('Socket connected:', socket.id);
      });

      socket.on('disconnect', () => {
        console.log('Socket disconnected');
      });

      return () => {
        socket.disconnect();
      };
    }, [socket]);

    return (
      <SocketContext.Provider value={socket}>
        {children}
      </SocketContext.Provider>
    );
  };

  export default SocketProvider;
